@font-face {
  font-family: 'BDOGrotesk';
  src: url('./fonts/BDOGrotesk-VF.woff2') format('woff2');
}

* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'BDOGrotesk', sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: #000000;
}
#model-loader-container {
  position: absolute;
  top: 20vh;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}
#model-loader-text {
  color: white;
  text-align: center;
}
#webgl-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
#panel-controls {
  display: none;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#content {
  margin: 0 auto;
}

#loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.loader {
  -webkit-perspective: 120px;
  -moz-perspective: 120px;
  -ms-perspective: 120px;
  perspective: 120px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.loader-image {
  height: 15rem;
  width: auto;
}

.loader-text {
  font-size: 1.5rem;
  color: white;
  margin: 1em;
}

.loader:before {
  border: 6px solid white;
  content: '';
  position: absolute;
  left: 25px;
  top: 25px;
  width: 50px;
  height: 50px;
  background-color: black;
  animation: flip 1.5s infinite;
}

@keyframes flip {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}
.hero-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  display: inline-block;
  max-width: 1920px;
}
.hero {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: min-content; /* 865px */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  background-color: transparent;
  min-height: 800px;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  gap: 0;
  z-index: 100;
}

.hero-title {
  flex-shrink: 0;
  flex-wrap: nowrap;
  width: 100%;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  font-size: 8vw;
  letter-spacing: 0em;
  text-transform: uppercase;
  line-height: 1.1;
  text-align: center;
}

.menu {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: min-content; /* 70px */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  z-index: 1;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
}

.menu-item {
  flex-shrink: 0;
  width: auto;
  height: auto;
  white-space: pre;
  position: relative;
  font-weight: 700;
  font-style: normal;
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0em;
  line-height: 1.2;
}

.main-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between
}

.small-about {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: min-content; /* 387px */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  background-color: black;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  gap: 40;
  border-radius: 0px 0px 0px 0px;
}

.about-title,
.footer-text {
  color: white;
}

.about-text {
  color: white;
}

#contact:hover {
  cursor: pointer;
}
#contact {
  color: white;
  text-decoration: underline;
  z-index: 10;
}
.footer-container {
  display: flex;
  flex-direction: column;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  min-height: 10rem;
}

.quote-container {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  mix-blend-mode: luminosity;
  background-color: #000000;
  min-height: 800px;
  overflow: hidden;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  gap: 10;
  max-width: 1920px;
  margin: 0 auto;
}

.quote-inner-container {
  background-image: url('images/background_cat.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  min-height: 20rem;
  filter: grayscale(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.quote-text {
  color: white;
  font-size: 4rem;
  text-align: center;
  flex-wrap: nowrap;
}
.contact-container {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: column;
  height: 100vh;
  padding: 2rem;
}
.contact-inner-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  gap: 0.5rem;
}
.contact-title {
  letter-spacing: -2.1px;
  line-height: 1.2;
  font-weight: 700;
  font-size: 4rem;
  width: 100%;
  text-align: center;
}
.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto;
  background-color: #ffffff;
}

.form-label {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 5px;
}

.form-input {
  padding: 1rem 1.5rem;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 1rem;
  color: #000000;
  background-color: #ffffff;
  outline: none;
  transition: border 0.3s ease;
}

.form-input.form-textarea {
  margin-top: 0.5rem;
  height: 150px;
  resize: none;
}

.form-input:focus {
  border-color: #000000;
}

.form-button {
  margin-top: 0.5rem;
  padding: 1rem 20px;
  border: none;
  border-radius: 5px;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #333333;
}

/* mobile breakpoint */
@media (max-width: 800px) {
  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* hide any after first */
  #datetime,
  #subtext {
    display: none;
  }
  .hero-title {
    font-size: 7.5vw;
  }
  .quote-text {
    font-size: 1.6rem;
  }
  .contact-inner-container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    gap: 0.5rem;
  }
  .footer-text {
    font-size: 4vw;
    margin-top: 1rem;
  }
  .menu-item {
    font-size: 4vw;
  }
}
@media screen and (min-width: 1980px) {
  .hero-title {
    font-size: 160px;
  }
}
